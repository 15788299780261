<template>
  <form @submit.prevent="onsubmit">
    <va-input
      class="mb-3"
      v-model="email"
      type="email"
      :label="$t('auth.email')"
      :error="!!emailErrors.length"
      :error-messages="emailErrors"
    />

    <va-input
      class="mb-3"
      v-model="password"
      type="password"
      :label="$t('auth.password')"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />

    <div class="auth-layout__options d-flex align--center justify--space-between">
      <va-checkbox v-model="keepLoggedIn" class="mb-0" :label="$t('auth.keep_logged_in')"/>
      <router-link class="ml-1 link" :to="{name: 'recover-password'}">{{ $t('auth.recover_password') }}</router-link>
    </div>

    <div class="d-flex justify--center mt-3">
      <va-button @click="onsubmit" class="my-0">{{ $t('auth.login') }}</va-button>
    </div>
  </form>
</template>

<script>

import axios from "axios";
import {useStore} from 'vuex';
import {computed} from "vue";
import {useColors} from "vuestic-ui";
import store from '@/store'




export default {
  name: 'login',

  data() {
    return {
      email: '',
      password: '',
      keepLoggedIn: false,
      emailErrors: [],
      passwordErrors: [],
    }
  },
  // setup() {
  //
  //   const store = useStore()
  //
  //   const token = computed({
  //     get: () => store.state.token,
  //     set: (value) => store.commit('setToken', value)
  //   })
  //   const isAuthenticated = computed({
  //     get: () => store.state.isAuthenticated,
  //     set: (value) => store.commit('changeAuthenticatedFlag', value)
  //   })
  //   const keepLogin = computed({
  //     set: (value) => store.commit('setTokenOnLocalStorage', value)
  //   })
  // return {
  //     token,
  //     isAuthenticated,
  //     keepLogin,
  // }
  // },
  computed: {
    formReady() {
      return !this.emailErrors.length && !this.passwordErrors.length
    },
  },
  methods: {
    onsubmit() {
      this.emailErrors = this.email ? [] : ['Email is required']
      this.passwordErrors = this.password ? [] : ['Password is required']
      if (!this.formReady) {
        return
      }
      try {
        const response = (axios.post(`${process.env.VUE_APP_SKIP_BASE_URL}/v1/auth/login`,
          {
            'email': this.email,
            'password': this.password,
          }
          , {
            headers: {
              'Content-Type': 'application/json',
              'Accept' : '*/*',
            },
          }).then(res => {

            this.token = res.data.data.token
            console.log(this.token)

            store.commit("setToken",this.token)
            store.commit("changeAuthenticatedFlag",true)
            store.commit("changeUserName",res.data.data.email)
            if (this.keepLoggedIn) {
              store.commit("setTokenOnLocalStorage",this.token)
            }

            this.$router.push({name: 'dashboard'})

        }).catch(error => {
            console.log(error)
            this.passwordErrors = 'wrong username or password'
            this.emailErrors = 'wrong username or password'
            if (!this.formReady) {
              return
            }
        }))
      } catch (e) {
        this.errors.push(e)
      }
    },
  },
}
</script>
